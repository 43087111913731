import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import PageLayout from "../../components/page-layout";
import Progress from "../../components/progress";

import * as style from "../steps.module.css";
import { getFormDataObject } from "../../utils/form-data";

import ukraineImage from "../../images/ukraine.png";

import "swiper/css";
import "swiper/css/navigation";

const fetchConsultants = async () => {
  const response = await fetch(
    "https://call-a-colleague.eu/api/v1/consultants"
  );
  return response.json();
};

const ConsultantSelection = ({
  id,
  items,
  selectedIndex,
  onSelectionChange,
}) => {
  return (
    <div id={id}>
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        onSlideChange={(swiper) => {
          onSelectionChange(swiper.activeIndex);
        }}
        className={style.swiper}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <div className={style.consultantCard}>
              <h2>{item.name}</h2>
              <p>{item.position}</p>
              <p>Speaks: {item.languages.join(", ")}</p>
              {item.location && <p>Location: {item.location}</p>}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <input
        type="hidden"
        name="consultant"
        value={(items[selectedIndex] || {}).id || -1}
      />
    </div>
  );
};

const pad = (s) => {
  s = s + "";
  return "00".slice(0, -s.length) + s;
};

const formatDate = (date) =>
  `${pad(date.getDate())}.${pad(
    date.getMonth() + 1
  )}.${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}`;

const TimeSelection = ({ id, schedule, selected, onSelectionChange }) => {
  return (
    <div>
      <select
        id={id}
        name="datetime"
        value={selected}
        onChange={(event) => {
          onSelectionChange(event.target.value);
        }}
        required
      >
        {schedule.length > 0 && <option value="">Choose a time</option>}
        {schedule.map((daySchedule, index) => (
          <React.Fragment key={index}>
            {daySchedule.map((dateString) => {
              const date = new Date(dateString);
              return (
                <option key={date.getTime()} value={date.getTime()}>
                  {formatDate(date)}
                </option>
              );
            })}
            <option disabled>~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~</option>
          </React.Fragment>
        ))}
        {schedule.length === 0 && (
          <option value="">Sorry, no free times available</option>
        )}
      </select>
    </div>
  );
};

const gatherCategories = (consultants = []) => {
  const categoryMap = consultants.reduce((result, consultant) => {
    (consultant.categories || []).forEach((category) => {
      result.set(category, (result.get(category) || 0) + 1);
    });
    return result;
  }, new Map());

  return [...categoryMap.keys()]
    .sort()
    .map((name) => ({ name, count: categoryMap.get(name) }));
};

const Step1 = ({ location }) => {
  const [consultants, setConsultants] = React.useState();
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [selectedConsultantIndex, setSelectedConsultantIndex] =
    React.useState(0);
  const [selectedTime, setSelectedTime] = React.useState("");

  // console.log("STEP 1:", location);

  useEffect(() => {
    fetchConsultants().then((response) => {
      if (response.error)
        throw new Error(`Failed to fetch consultants: ${response.message}`);
      setConsultants(response.consultants);
    });
  }, []);

  const categories = gatherCategories(consultants);
  // console.log("CATEGORIES:", categories);
  // console.log("SELECTED:", selectedCategory);

  const filteredConsultants = (consultants || []).filter(
    (consultant) =>
      selectedCategory === "all" ||
      (consultant.categories || []).includes(selectedCategory)
  );
  const selectedConsultant = filteredConsultants[selectedConsultantIndex];

  return (
    <PageLayout
      title="Call A Colleague - Step 1"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
    >
      <Progress step={1} stepCount={2}>
        <section id="step1" className={style.bookSection}>
          <h2>Your Mentor</h2>
          <form
            className={style.form}
            onSubmit={async (event) => {
              event.preventDefault();

              const formData = getFormDataObject(event.target);
              navigate("/step2", { state: { consultants, step1: formData } });
            }}
          >
            <div>
              <label htmlFor="form-category">
                {consultants
                  ? "Pick your professional area"
                  : "Loading mentors, please wait..."}
              </label>
              <select
                id="form-category"
                name="category"
                value={selectedCategory}
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
                required
                disabled={!consultants}
              >
                <option value="">Choose an area</option>
                {categories.map((category) => (
                  <option key={category.name} value={category.name}>
                    {category.name} ({category.count})
                  </option>
                ))}
                <option value="all">
                  All categories ({(consultants || []).length})
                </option>
              </select>
            </div>

            {selectedCategory && (
              <div>
                <label htmlFor="form-consultant">Pick your mentor</label>
                <ConsultantSelection
                  id="form-consultant"
                  items={filteredConsultants}
                  selectedIndex={selectedConsultantIndex}
                  onSelectionChange={setSelectedConsultantIndex}
                />
              </div>
            )}

            {selectedCategory && (
              <div>
                <label htmlFor="form-datetime">
                  Pick a time for the call <br />
                  Timezone:{" "}
                  <b>{Intl.DateTimeFormat().resolvedOptions().timeZone}</b>
                </label>
                <TimeSelection
                  id="form-datetime"
                  schedule={selectedConsultant?.schedule || []}
                  selected={selectedTime}
                  onSelectionChange={setSelectedTime}
                />
              </div>
            )}

            <input type="submit" value="Next" disabled={!consultants} />
          </form>
        </section>
      </Progress>
    </PageLayout>
  );
};

export default Step1;
